// Components
import AddonsNeedUpgradeContent from '@/components/elements/addons/AddonsNeedUpgradeContent'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Utils
import { get } from 'lodash'

export default {
  name: 'AddonsNeedUpgrade',
  components: {
    AddonsNeedUpgradeContent,
    VuetifyToolBar
  },
  data() {
    return {
      id: get(this.$route, 'params.id', null)
    }
  },
  watch: {
    /**
     * Watch the router because we use the same component
     * to differents routes and we need that it can reload itself
     */
    $route(to) {
      this.id = to.params.id
    }
  }
}
